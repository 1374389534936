/* eslint-disable no-undef */
window._wq = window._wq || [];

_wq.push({
	id: '_all',
	onReady(wistiaEmbed) {
		const media = wistiaEmbed.container.parentNode;
		const playPause = media.querySelector('.media__play-pause');

		if (null !== playPause) {
			playPause.addEventListener('click', () => {
				if ('playing' === wistiaEmbed.state()) {
					wistiaEmbed.pause();
					playPause.classList.add('media__play-pause--play');
				} else {
					wistiaEmbed.play();
					playPause.classList.remove('media__play-pause--play');
				}
			});
		}
	},
});
